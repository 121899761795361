import React from 'react';
import { Link } from 'react-router-dom';
import { Header, HeaderContainer, Menu, Avatar } from './styles';
import logoCSN from '../../assets/logoCSN.svg';
import { useAuth } from '../../hooks/auth';

const Heading: React.FC = () => {
  const { signOut, user } = useAuth();

  return (
    <Header>
      <HeaderContainer>
        <img src={logoCSN} alt="Gescondo" />
        <Menu>
          <ul>
            <Link to="/communicated">
              <button type="button">Comunicados</button>
            </Link>
          </ul>
          <ul>
            <button type="button">Boletos</button>
          </ul>
          <ul>
            <Link to="/appointments">
              <button type="button">Agendamentos</button>
            </Link>
          </ul>
          <ul>
            <button type="button" onClick={signOut}>
              Sair
            </button>
          </ul>
        </Menu>

        <Avatar>
          <div>
            <span>Bem-vindo,</span>
            <Link to="/profile">
              <strong>{user.name}</strong>
            </Link>
          </div>
          <img src={user.avatar_url} alt={user.name} />
        </Avatar>
      </HeaderContainer>
    </Header>
  );
};

export default Heading;
